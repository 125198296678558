import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "architecture-overview"
    }}>{`Architecture Overview`}</h1>
    <hr></hr>
    <p>{`In this overview we will talk mostly about the Core of Hegel. It should help you to get a high-level understanding of the Hegel architecture.`}</p>
    <p>{`Core is placed in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core"
      }}>{`@hegel/core`}</a>{` and contains the main logic of type checking and type inference.
The main logic of Core: take `}<a parentName="p" {...{
        "href": ""
      }}>{`Abstract Syntax Tree`}</a>{` and convert it into symbols table (inside Hegel it's called `}<inlineCode parentName="p">{`moduleScope`}</inlineCode>{`) which contains information about variables, scopes, and types.`}</p>
    <p>{`So, AST conversion starts from tree traverse which is placed in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/utils/traverse.js"
      }}><inlineCode parentName="a">{`src/utils/traverse.js`}</inlineCode></a>{`.
In traverse we have 3 steps:`}</p>
    <h4 {...{
      "id": "precompute"
    }}><strong parentName="h4">{`Precompute`}</strong></h4>
    <p><a parentName="p" {...{
        "href": "#precomute"
      }}>{`The Precompute step`}</a>{` is a step in `}<inlineCode parentName="p">{`traverseTree`}</inlineCode>{` function inside `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/utils/traverse.js"
      }}><inlineCode parentName="a">{`src/utils/traverse.js`}</inlineCode></a>{` which process AST node before the node children was processed.`}</p>
    <p>{`It's needed to add initial information about `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/variable-info.js"
      }}>{`variables`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/variable-scope.js"
      }}>{`scopes`}</a>{`. Also, we use `}<a parentName="p" {...{
        "href": "#precomute"
      }}>{`Precompute`}</a>{` for type refinement (which main logic is placed in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/inference/refinement.js"
      }}>{`src/inference/refinement.js`}</a>{`).`}</p>
    <h4 {...{
      "id": "middlecompute"
    }}><strong parentName="h4">{`Middlecompute`}</strong></h4>
    <p>{`The most simple type of computation. The step processes node children one-by-one without deep processing. It's needed because JavaScript contains hoisting.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const a = getA();

function getA() {
  return 1;
}
`}</code></pre>
    <p>{`This step hoist Function Declarations and Interface Declarations (only inside `}<inlineCode parentName="p">{`.d.ts`}</inlineCode>{` files) by adding raw nodes into symbols table (we will do lazy processing of the nodes if these nodes are used before own declaration or will process it (in `}<a parentName="p" {...{
        "href": "#precomute"
      }}>{`Precompute step`}</a>{` ) when we find their declarations).`}</p>
    <p>{`Also, it's used for fast adding class and object methods, because in JavaScript we can call a method in another method which currently are not be processed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class Main {
  constructor() {
    this.a = this.getA();
  }

  getA() {
    return 1;
  }
}
`}</code></pre>
    <h4 {...{
      "id": "postcompute"
    }}><strong parentName="h4">{`Postcompute`}</strong></h4>
    <p>{`In oposite to `}<a parentName="p" {...{
        "href": "#precompute"
      }}>{`Precompute step`}</a>{`, `}<a parentName="p" {...{
        "href": "#postcumpute"
      }}>{`Postcompute step`}</a>{` processes AST node after all node's children were processed.
We use the step for `}<a parentName="p" {...{
        "href": "#type-inference"
      }}>{`type inference`}</a>{` (which main logic is placed in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/inference/"
      }}><inlineCode parentName="a">{`src/inference`}</inlineCode>{` directory`}</a>{`) and collecting of `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/meta/call-meta.js"
      }}>{`Calls Infromation`}</a>{`. The `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/meta/call-meta.js"
      }}>{`Calls Infromation`}</a>{` is used in `}<a parentName="p" {...{
        "href": "#checking-step"
      }}>{`Checking Step`}</a></p>
    <h3 {...{
      "id": "type-inference"
    }}>{`Type Inference`}</h3>
    <p>{`So, type inference logics for each literal are placed in (which main logic is placed in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/inference/"
      }}><inlineCode parentName="a">{`src/inference`}</inlineCode>{` directory`}</a>{`). For an expression in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/call.js"
      }}><inlineCode parentName="a">{`src/type-graph/call.js`}</inlineCode>{` file`}</a>{` which adds `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/meta/call-meta.js"
      }}>{`Calls Infromation`}</a>{` ).`}</p>
    <p>{`For literals, we have a really simple logic. For example, code for type inference of a simple type:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`switch (currentNode.type) {
  case NODE.NUMERIC_LITERAL:
    result = Type.term(currentNode.value, {
      isSubtypeOf: Type.Number
    });
    break;
  case NODE.BIGINT_LITERAL:
    result = Type.term(\`\${currentNode.value}n\`, {
      isSubtypeOf: Type.BigInt
    });
    break;
  case NODE.TEMPLATE_LITERAL:
    result = Type.String;
    break;
  case NODE.STRING_LITERAL:
    result = Type.term(\`'\${currentNode.value}'\`, {
      isSubtypeOf: Type.String
    });
    break;
  case NODE.BOOLEAN_LITERAL:
    result = Type.term(currentNode.value);
    break;
  case NODE.NULL_LITERAL:
    result = Type.Null;
    break;
  case NODE.REG_EXP_LITERAL:
    result = Type.find("RegExp");
    break;
`}</code></pre>
    <p>{`The tricky moments start for `}<a parentName="p" {...{
        "href": "#function-inference"
      }}>{`Function`}</a>{` and `}<a parentName="p" {...{
        "href": "#class-and-object-inference"
      }}>{`Objects/Classes`}</a>{`.`}</p>
    <h4 {...{
      "id": "function-inference"
    }}>{`Function Inference`}</h4>
    <p>{`First of all, we try to collect any defined type for arguments or return type at `}<a parentName="p" {...{
        "href": "#precompute"
      }}>{`Precompute step`}</a>{`, if argument or function doesn't have a type annotation then we create `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/types/type-var.js"
      }}><inlineCode parentName="a">{`TypeVar`}</inlineCode></a>{` (this type represents generic variables), and add it to generic arguments list. The code is placed in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/inference/function-type.js"
      }}>{`/src/inference/function-type.js`}</a>{` in `}<inlineCode parentName="p">{`inferenceFunctionLiteralType`}</inlineCode>{`.`}</p>
    <p>{`After processing every child node, we will try to find the type of an argument or return type by `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/meta/call-meta.js"
      }}>{`Call Information`}</a>{`, which is taken from all child `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/variable-scope.js"
      }}>{`VariableScopes`}</a>{` of the function scope.`}</p>
    <p>{`Arguments Resolving Colde is placed in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/inference/function-type.js"
      }}>{`/src/inference/function-type.js`}</a>{` in `}<inlineCode parentName="p">{`resolveOuterTypeVarsFromCall`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "class-and-object-inference"
    }}>{`Class and Object inference`}</h4>
    <p>{`From the other side, inference of class or object type, because we have `}<inlineCode parentName="p">{`this`}</inlineCode>{` keyword in JavaScript. It means that when we try to use any property or method from other methods in object or class instance we should have access to all the object or class methods and properties wherever it's defined.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const obj = {
  c: 4,
  a() {
    return this.b();
  },
  b() {
    return this.c;
  },
};
`}</code></pre>
    <p>{`So, we need to add methods and properties lazily. First of all, we add all methods and properties raw nodes in object/class type (We make it in `}<a parentName="p" {...{
        "href": "#middlecompute"
      }}>{`Middlecompute step`}</a>{`), and, if we try to access a property or method then we traverse saved node and infer the type of the method or property (We make it by `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/scope.js"
      }}>{`Scope`}</a>{` static method `}<inlineCode parentName="p">{`addAndTraverseNodeWithType`}</inlineCode>{`).`}</p>
    <h3 {...{
      "id": "checking-step"
    }}>{`Checking Step`}</h3>
    <p>{`This step is described in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/checking/index.js"
      }}>{`/src/checking/index.js`}</a>{` and only take all `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/meta/call-meta.js"
      }}>{`Calls Information`}</a>{` from every defined `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/variable-scope.js"
      }}>{`VariableScope`}</a>{` (this calls is stored in `}<inlineCode parentName="p">{`calls`}</inlineCode>{` property of every instance of `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/variable-scope.js"
      }}>{`VariableScope class`}</a>{`) and check that every defined argument `}<a parentName="p" {...{
        "href": "#principal-type"
      }}>{`is a principal type for`}</a>{` given argument at the position.
`}<inlineCode parentName="p">{`checkCalls`}</inlineCode>{` function in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/checking/index.js"
      }}>{`/src/checking/index.js`}</a>{`. We call the function in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/type-graph.js"
      }}>{`/src/type-graph/type-graph.js `}<inlineCode parentName="a">{`createModuleScope`}</inlineCode>{` function`}</a>{` )`}</p>
    <h3 {...{
      "id": "types"
    }}>{`Types`}</h3>
    <p>{`All types in Hegel Core are defined in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/types/"
      }}>{`/src/type-graph/types/`}</a>{` and every type is a child for the base `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/types/type.js"
      }}>{`Type class`}</a>{`.`}</p>
    <h4 {...{
      "id": "principal-type"
    }}>{`Principal Type`}</h4>
    <p>{`Principal type is a type which equals to current or will be a supertype of current. From early version of `}<inlineCode parentName="p">{`isPrincipalTypeFor`}</inlineCode>{` function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`isPrincipalTypeFor(type: Type) {
  return this.equalsTo(type) || this.isSuperTypeFor(type);
}
`}</code></pre>
    <p>{`Each type defined own `}<inlineCode parentName="p">{`equalsTo`}</inlineCode>{` and `}<inlineCode parentName="p">{`isSuperTypeFor`}</inlineCode>{`. As example, `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/types/union-type.js"
      }}>{`UnionType class`}</a>{` defined `}<inlineCode parentName="p">{`isSuperTypeFor`}</inlineCode>{` as (simplified version without performance details):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`isSuperTypeFor(anotherType: Type): boolean {
  if (anotherType instanceof UnionType) {
    for (const variantType of anotherType.variants) {
      if (!this.variants.some(type => type.isPrincipalTypeFor(variantType))) {
        return false;
      }
    }
    return true;
  }
  return this.variants.some(type =>
    type.isPrincipalTypeFor(anotherType)
  );
}
`}</code></pre>
    <h4 {...{
      "id": "bottomtype"
    }}>{`$BottomType`}</h4>
    <p>{`One of the interesting architecture decisions is `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/types/bottom-type.js"
      }}><inlineCode parentName="a">{`$BottomType`}</inlineCode></a>{`. This type behaves like a `}<inlineCode parentName="p">{`Promise`}</inlineCode>{` in types world. It means that when we want to apply `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/types/union-type.js"
      }}>{`GenericType`}</a>{` (which behaves like a function in types world) any `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/types/type-var.js"
      }}>{`TypeVar`}</a>{` we can reduce the cost of `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/types/type.js"
      }}><inlineCode parentName="a">{`changeAll`}</inlineCode>{` function`}</a>{` and instead of deep changing of generic arguments to another type variables, we can return `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/types/bottom-type.js"
      }}><inlineCode parentName="a">{`$BottomType`}</inlineCode></a>{` which say that we want to apply new type variables instead old ones, and if we will replace a new type variable to a specific type instead of one more call of `}<inlineCode parentName="p">{`changeAll`}</inlineCode>{`, we only change new type variable to a specific type and that's all.`}</p>
    <h3 {...{
      "id": "refinement"
    }}>{`Refinement`}</h3>
    <p>{`Another tricky and interesting moment in Hegel is type refinement. It's tricky because, for refinement variable (for example), we need to create a new `}<inlineCode parentName="p">{`VariableScope`}</inlineCode>{` and add a refined type of the variable in the scope. For example the next code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const a: number | null = 14;
if (a !== null) {
  const b = a + 12;
}
`}</code></pre>
    <p>{`After the decision that inside `}<inlineCode parentName="p">{`if`}</inlineCode>{` scope the variable `}<inlineCode parentName="p">{`a`}</inlineCode>{` will be `}<inlineCode parentName="p">{`number`}</inlineCode>{` type (we decide it in the `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/inference/equals-refinement.js"
      }}>{`/src/inference/equals-refinement.js`}</a>{` ), we will add into `}<inlineCode parentName="p">{`if`}</inlineCode>{` scope variable `}<inlineCode parentName="p">{`a`}</inlineCode>{` with new type `}<inlineCode parentName="p">{`number`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`number | null`}</inlineCode>{` (check `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/inference/refinement.js"
      }}>{`/src/inference/refinement.js `}<inlineCode parentName="a">{`refinement`}</inlineCode>{` function`}</a>{` ).`}</p>
    <p>{`But, sometimes we should save a previous type to stay sound. An example is objects.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function assert(obj: { a: number | string }): { a: number } | undefined {
  if (typeof obj.a === "number") {
    // With defined algorithm "obj" should be { a: number }, but it's not
    return obj;
  }
}

const original: { a: number | string } = { a: 2 };
const refinement = assert(original); // { a: number }
original.a = "str";

if (refinement !== undefined) {
  // TypeError
  void refinement.a.toFixed(0);
}
`}</code></pre>
    <p>{`So, to solve the problem we use `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/tree/master/packages/core/src/type-graph/types/refinemented-type.js"
      }}><inlineCode parentName="a">{`$RefinementedType`}</inlineCode></a>{`, which saves an original type and refined type.`}</p>
    <h3 {...{
      "id": "ps"
    }}>{`P.S.`}</h3>
    <p>{`If you need more details in the overview then ask the questions in `}<a parentName="p" {...{
        "href": "https://github.com/JSMonk/hegel/issues"
      }}>{`Hegel Issues`}</a>{`, and we will add more information about the weird block or will answer the question in the issue.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      